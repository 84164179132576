import React from 'react';
import { styled } from '@mui/material';
import { useQueryClient } from 'react-query';
import {
  DatagridConfigurable,
  DeleteWithConfirmButton,
  FunctionField,
  Identifier,
  ListProps,
  NumberField,
  RaRecord,
  TextField,
  useCreatePath,
} from 'react-admin';
import { QuantField } from '../common/QuantField';
import { URL_CART_TOTALS } from './types';
import { RESOURCE_CATALOG } from '../catalog';
import { getCurrencyOpts, numAsCurrency } from '../../utils';

const DeleteWithConfirmButtonStyled = styled(DeleteWithConfirmButton)`
  min-width: 32px;

  .MuiButton-startIcon {
    margin: 0;
  }
`;

export const CartTableView: React.FC<Omit<ListProps, 'children'>> = () => {
  const queryClient = useQueryClient();
  const createPath = useCreatePath();
  const handleRowClick = (id: Identifier, resource: string, record: RaRecord) => {
    return createPath({ resource: RESOURCE_CATALOG, id: record.good.id, type: 'show' });
  };

  // omit={['id']}
  //
  return (
    <>
      <DatagridConfigurable rowClick={handleRowClick} bulkActionButtons={false}>
        {/*
      <NumberField source="id" />
      <NumberInput name="qnt2" source="quant" label="Кол-во" />
      */}
        <TextField source="good.code" label="Артикул" />
        <TextField source="good.barcode" sortable={false} label="Штрих-код" />
        <TextField source="good.name" label="Наименование" />
        <QuantField
          label="Кол-во"
          // textAlign="center"
          sortable={false}
          onSuccessUpdate={() => queryClient.invalidateQueries(URL_CART_TOTALS)}
        />
        {/*
      <NumberField source="quant" sortable={false} label="Кол-во" />
      */}
        <NumberField source="price_i" sortable={false} label="Цена" options={getCurrencyOpts()} />
        <FunctionField
          source="sum_i"
          sortable={false}
          label="Сумма"
          textAlign="right"
          render={(record: RaRecord) => record && numAsCurrency(record.quant * record.price_i)}
        />
        <DeleteWithConfirmButtonStyled
          label=" "
          confirmTitle=""
          confirmContent="Удалить товар из корзины?"
        />
      </DatagridConfigurable>
    </>
  );
};
