const ruMessages = {
  orders: {
    name: 'Заказ |||| Заказы',
    empty: 'Нет заказов',
    fields: {
      dttm: 'Дата заказа',
    },
  },
};

export default ruMessages;
