import React from 'react';
import { Create } from 'react-admin';
import { FaceForm } from './FaceForm';

export const FaceCreate = () => {
  return (
    <Create>
      <FaceForm />
    </Create>
  );
};
