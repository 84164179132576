import { CanvassList } from './CanvassList';
import { CanvassShow } from './CanvassShow';

export const RESOURCE_CANVASS = 'canvass';

export default {
  name: RESOURCE_CANVASS,
  // options: { label: 'Товары' },
  list: CanvassList,
  show: CanvassShow,
};
