import React from 'react';
import { useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardActions, CardContent, CardHeader } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useApiFetch } from '../../hooks';
import { ICartTotalsDto, URL_CART_TOTALS } from '../cart';
import { numAsCurrency } from '../../utils';

export const CartTotals = () => {
  const notify = useNotify();
  const { data } = useApiFetch<ICartTotalsDto>(URL_CART_TOTALS, {
    onError: (error) => notify(String(error), { type: 'error', autoHideDuration: 3000 }),
  });

  return (
    <Grid container gap={2} sx={{ mx: 2 }}>
      <Grid>
        Кол-во:
        <Box component="span" sx={{ ml: 1, fontWeight: 'bold' }}>
          {data?.total_quant ?? '?'}
        </Box>
      </Grid>
      <Grid>
        Сумма:
        <Box component="span" sx={{ ml: 1, fontWeight: 'bold' }}>
          {data?.total_sum_i != undefined ? numAsCurrency(data.total_sum_i) : '?'}
        </Box>
      </Grid>
    </Grid>
  );
};

export const CartTotalsWidget = () => {
  const navigate = useNavigate();
  return (
    <Card>
      <CardHeader
        title={'Корзина'}
        titleTypographyProps={{ variant: 'h6' }}
        sx={{ paddingBottom: 0 }}
      />
      <CardContent>
        <CartTotals />
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => navigate('/cart')}>
          Перейти
        </Button>
      </CardActions>
    </Card>
  );
};
