import { TranslationMessages } from 'react-admin';
import russianMessages from './ra-language-russian';
import ruCatalog from '../features/catalog/i18n/ru';
import ruCanvass from '../features/canvass/i18n/ru';
import ruCart from '../features/cart/i18n/ru';
import ruOrders from '../features/orders/i18n/ru';
import ruWishlist from '../features/wishlist/i18n/ru';

const customRussianMessages: TranslationMessages = {
  ...russianMessages,
  resources: {
    ...ruCatalog,
    ...ruCanvass,
    ...ruCart,
    ...ruOrders,
    ...ruWishlist,
  },
};

export default customRussianMessages;
