/**
 *
 */
export const getCurrencyOpts = () => {
  return {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
  };
};

/**
 *
 */
export const numAsCurrency = (value: number): string => {
  return value.toLocaleString(undefined, getCurrencyOpts());
};
