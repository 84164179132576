import { FaceList } from './FaceList';
import { FaceCreate } from './FaceCreate';
import { FaceEdit } from './FaceEdit';

const URL = 'faces';

export default {
  name: URL,
  list: FaceList,
  create: FaceCreate,
  edit: FaceEdit,
  // show: GoodShow,
};
