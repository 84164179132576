import React from 'react';
import { Button, Card, CardContent, CardHeader } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { NotificationType, useNotify } from 'react-admin';
import { apiFetch } from '../../dataProvider';

const NOTIFY: Record<string, { type: NotificationType; autoHideDuration: number }> = {
  success: {
    type: 'success',
    autoHideDuration: 3000,
  },
  error: {
    type: 'error',
    autoHideDuration: 8000,
  },
};

export const UploadFile = () => {
  const notify = useNotify();

  const doUpload = (file: File) => {
    const formData = new FormData();
    formData.append(file.name, file);
    return apiFetch('upload', undefined, {
      method: 'POST',
      body: formData,
    });
  };

  const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      doUpload(file)
        .then(() => {
          notify('Файл успешно отправлен', NOTIFY.success);
        })
        .catch((e) => {
          notify(String(e), NOTIFY.error);
        });
    }
  };

  return (
    <Card sx={{ my: 2 }}>
      <CardHeader title="Отправка файла заказа" />
      <CardContent>
        <Button component="label" variant="contained" startIcon={<UploadFileIcon />}>
          Выбрать файл
          <input type="file" accept=".xls,.xlsx" hidden onChange={handleUploadFile} />
        </Button>
      </CardContent>
    </Card>
  );
};
