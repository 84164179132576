import React from 'react';
import { Typography } from '@mui/material';
import {
  Create,
  // required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar as RaToolbar,
  ToolbarProps,
} from 'react-admin';
import Grid from '@mui/material/Unstable_Grid2';
import { CartTotals } from './CartTotals';

const Toolbar = (props: ToolbarProps) => (
  <RaToolbar {...props}>
    <SaveButton label="Оформить заказ" color="success" alwaysEnable />
  </RaToolbar>
);

export const CartOrder = () => {
  return (
    <Create resource="orders" redirect="list" sx={{ flexGrow: 1 }}>
      <SimpleForm toolbar={<Toolbar />}>
        <Grid container justifyContent="space-between" width="100%">
          <Typography variant="h6" gutterBottom>
            Заказ
          </Typography>
          <Grid container alignItems="baseline">
            <Typography variant="h6" component="div" sx={{ lineHeight: '1.6' }}>
              Итого
            </Typography>
            <CartTotals />
          </Grid>
        </Grid>
        <TextInput
          name="remark"
          source="remark"
          label="Примечание"
          variant="outlined"
          // validate={required()}
          sx={{ width: '100%' }}
        />
      </SimpleForm>
    </Create>
  );
};
