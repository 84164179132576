import { CartList } from './CartList';

const URL = 'cart';

export default {
  name: URL,
  // options: { label: 'Товары' },
  list: CartList,
};

export * from './types';
