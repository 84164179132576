import React from 'react';
import { Datagrid, ListProps, NumberField, TextField } from 'react-admin';
import { getCurrencyOpts } from '../../utils';

export const GoodTableView: React.FC<Omit<ListProps, 'children'>> = () => {
  return (
    <Datagrid rowClick="show" bulkActionButtons={false}>
      {/*
      <NumberField source="id" />
      */}
      <TextField source="code" />
      <TextField source="barcode" sortable={false} />
      <TextField source="name" />
      <NumberField source="price.value" sortable={false} label="Цена" options={getCurrencyOpts()} />
      <NumberField source="cart_quant" label="В корзине" />
    </Datagrid>
  );
};
