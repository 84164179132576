import React from 'react';
import { required, SimpleForm, TextInput, useRecordContext } from 'react-admin';
import { Card, CardHeader } from '@mui/material';

export const FaceForm = () => {
  const rec = useRecordContext();
  return (
    <Card>
      <CardHeader title="Контрагент" subheader={rec ? 'редактирование' : 'создание'} />
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <TextInput source="shortname" />
      </SimpleForm>
    </Card>
  );
};
