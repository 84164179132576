import React from 'react';
import { Button, ButtonGroup, styled, useMediaQuery } from '@mui/material';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import { Theme } from '@mui/material/styles';

export type TListViewMode = 'grid' | 'table';

interface IProps {
  viewMode: TListViewMode;
  setViewMode: (mode: TListViewMode) => void;
}

const ButtonGroupStyled = styled(ButtonGroup)`
  .MuiButton-sizeSmall {
    font-size: 18px;
  }

  .MuiButton-sizeLarge {
    font-size: 24px;
    padding: 12px;
  }

  .MuiButton-textSecondary {
    color: rgba(0, 0, 0, 0.54);
  }
`;

export const ViewModeToggler: React.FC<IProps> = ({ viewMode, setViewMode }) => {
  const isXSmall = useMediaQuery<Theme>((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <ButtonGroupStyled
      variant="text"
      size={isXSmall ? 'large' : 'small'}
      aria-label="text primary button group"
    >
      <Button
        color={viewMode == 'grid' ? 'primary' : 'secondary'}
        onClick={() => setViewMode('grid')}
      >
        <ViewModuleIcon fontSize="inherit" />
      </Button>
      <Button
        color={viewMode == 'table' ? 'primary' : 'secondary'}
        onClick={() => setViewMode('table')}
      >
        <ViewListIcon fontSize="inherit" />
      </Button>
    </ButtonGroupStyled>
  );
};
