import React from 'react';
import { Admin, Resource, defaultLightTheme, defaultDarkTheme, CustomRoutes } from 'react-admin';
import { Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { authProvider } from './authProvider';
import { dataProvider } from './dataProvider';
import { i18nProvider } from './i18n';

import Layout from './components/Layout';
import Dashboard from './features/dashboard';
import catalog from './features/catalog';
import cart from './features/cart';
import orders from './features/orders';
import canvass from './features/canvass';
import wishlist from './features/wishlist';
import faces from './features/faces';
import { UploadFile } from './features/upload-file';

export default function App() {
  return (
    <BrowserRouter>
      <Admin
        disableTelemetry
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        layout={Layout}
        dashboard={Dashboard}
        lightTheme={defaultLightTheme}
        darkTheme={defaultDarkTheme}
        defaultTheme="light"
      >
        <Resource {...catalog} />
        <Resource {...cart} />
        <Resource {...orders} />
        <Resource {...canvass} />
        <Resource {...wishlist} />
        <Resource {...faces} />

        <CustomRoutes>
          <Route path="/upload-file" element={<UploadFile />} />
        </CustomRoutes>
      </Admin>
    </BrowserRouter>
  );
}
