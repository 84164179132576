import React from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import {
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
  useResourceContext,
} from 'react-admin';

/**
 *
 */
interface IMenuItemDef {
  label: string;
  disabled?: boolean;
  url: string;
  getParams?: () => any;
}

/**
 *
 */
interface IProps {
  label?: string;
}

// noinspection JSUnusedLocalSymbols
/**
 * props используется для указания label в настройке колонок
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ContextMenu = (props: IProps) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const resourceName = useResourceContext();
  const record = useRecordContext();

  const options: Record<string, IMenuItemDef> = {
    deactivate: {
      label: 'Отменить',
      disabled: record.actived == 0,
      url: `${resourceName}/${record.id}/deactivate`,
    },
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  };

  const handleOnClose = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setAnchorEl(null);
    e.stopPropagation();
  };

  const handleItemClick = (item: IMenuItemDef) => {
    dataProvider
      .create(item.url, {
        data: item.getParams?.(),
      })
      .then(refresh)
      .catch((e) => {
        notify(String(e), { type: 'error', autoHideDuration: 3000 });
      });
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="context-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size={'small'}
      >
        <MoreVertIcon fontSize={'small'} />
      </IconButton>
      <Menu
        id="context-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleOnClose}
      >
        {Object.keys(options).map((key) => {
          const item = options[key];
          return (
            <MenuItem
              value={key}
              key={key}
              disabled={item.disabled}
              onClick={(e) => {
                e.preventDefault();
                setAnchorEl(null);
                handleItemClick(item);
                e.stopPropagation();
              }}
            >
              {options[key].label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
