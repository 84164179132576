import * as React from 'react';
import { Box, Switch, FormControlLabel } from '@mui/material';
import { useListFilterContext } from 'react-admin';

interface IProps {
  name: string;
  label: string;
  checkedValue: string | number;
  alwaysOn: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SwitchInputFilter: React.FC<IProps> = ({ name, label, checkedValue, alwaysOn }) => {
  const { filterValues, displayedFilters, setFilters } = useListFilterContext();

  const handleChange = () => {
    const newFilterValues = { ...filterValues };
    if (typeof filterValues[name] !== 'undefined') {
      delete newFilterValues[name];
    } else {
      newFilterValues[name] = checkedValue;
    }
    setFilters(newFilterValues, displayedFilters);
  };
  return (
    <Box sx={{ marginBottom: 1, marginLeft: 1 }}>
      <FormControlLabel
        control={
          <Switch
            checked={typeof filterValues[name] !== 'undefined'}
            onChange={handleChange}
            color="primary"
            name={name}
          />
        }
        label={label}
      />
    </Box>
  );
};
