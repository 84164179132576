import React from 'react';
import { List as RAList, ListProps, useResourceContext } from 'react-admin';
import { Title } from './Title';

export const List: React.FC<ListProps> = ({ children, ...props }) => {
  const resourceName = useResourceContext();
  // const { resource } = props;
  // console.log('== listProps', resourceName);
  // console.log('== listProps', props.resource.name);
  return (
    <>
      <RAList
        title={<Title subTitle={`resources.${resourceName}.name`} args={{ smart_count: 2 }} />}
        {...props}
      >
        {children}
      </RAList>
    </>
  );
};
