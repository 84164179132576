const ruMessages = {
  canvass: {
    name: 'Канвас |||| Канвасы',
    empty: 'Нет канвасов',
    fields: {
      name: 'Наименование',
      code: 'Артикул',
      barcode: 'Штрих-код',
      new: 'Новинки',
    },
  },
};

export default ruMessages;
