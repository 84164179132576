import React from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { RaRecord } from 'react-admin';
import { useRecMutate } from '../../hooks/useRecMutate';

export interface IInputQtyProps {
  resourceAdd?: string;
  fieldQuant?: string;
  captionBuy?: string;
  captionBuyed?: string;
}

interface IProps extends IInputQtyProps {
  rec: RaRecord;
}

export const InputQty: React.FC<IProps> = ({
  rec,
  resourceAdd = 'cart',
  fieldQuant = 'cart_quant',
  captionBuy = 'Купить',
  captionBuyed = 'В корзине',
}) => {
  const curValue: number | null = rec[fieldQuant];
  const [value, setValue] = React.useState<number>(curValue ?? 1);

  React.useEffect(() => {
    setValue(curValue ?? 1);
  }, [curValue]);

  const { mutate, isLoading } = useRecMutate(`${resourceAdd}/add`, rec.id, (rec, vars) => {
    rec[fieldQuant] = vars.quant;
  });

  const handleAddToCart = () => {
    mutate({
      good_id: rec.id,
      price_i: rec.price?.value,
      quant: value,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const n = Number(event.target.value);
    setValue(!isNaN(n) ? n : 1);
  };

  if (!rec.stock_quant) {
    return <Typography variant="body1">{rec.stock_quant_info}</Typography>;
  }

  const inCart = curValue == value;

  return (
    <Box
      sx={{
        marginTop: 1,
        display: 'flex',
        alignItems: 'flex-start',
        gap: 1,
      }}
    >
      <TextField
        label="Количество"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
        variant="outlined"
        helperText={`Доступно: ${rec.stock_quant_info}`}
        value={value}
        onChange={handleChange}
        sx={{ marginBottom: 0, maxWidth: 120 }}
        error={value <= 0}
      />

      <Button
        size="medium"
        color={inCart ? 'success' : 'primary'}
        variant="contained"
        disabled={isLoading || value <= 0}
        onClick={handleAddToCart}
        sx={{
          marginTop: 1,
        }}
      >
        {inCart ? captionBuyed : captionBuy}
      </Button>
    </Box>
  );
};
