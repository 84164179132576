import { GoodList } from './GoodList';
import { GoodShow } from './GoodShow';

export const RESOURCE_CATALOG = 'catalog';

export default {
  name: RESOURCE_CATALOG,
  // options: { label: 'Товары' },
  list: GoodList,
  show: GoodShow,
};
