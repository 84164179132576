import React from 'react';
import { Typography } from '@mui/material';
import { Identifier, SimpleShowLayout, TextField, useGetOne } from 'react-admin';
import { OrderItems } from './OrderItems';

interface IProps {
  order_id: Identifier;
}

export const OrderDetails = ({ order_id }: IProps) => {
  const { data: record } = useGetOne<any>('orders', { id: order_id });

  if (!record) {
    return null;
  }

  return (
    <SimpleShowLayout record={record}>
      <TextField label="Примечание" source="remark" emptyText="- нет -" />

      <Typography variant="h6">Позиции заказа</Typography>

      <OrderItems />
    </SimpleShowLayout>
  );
};
