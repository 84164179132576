import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { DataProvider, useDataProvider } from 'react-admin';

export interface ApiFetchResult<RecordType = any> {
  data: RecordType;
}

export interface DataProviderEx extends DataProvider {
  apiFetch: <RecordType = any>(url: string) => Promise<ApiFetchResult<RecordType>>;
}

export type UseApiFetch<RecordType = any> = UseQueryResult<RecordType>;

export const useApiFetch = <RecordType = any>(
  url: string,
  options?: UseQueryOptions<RecordType>,
): UseApiFetch<RecordType> => {
  const dataProvider = useDataProvider() as DataProviderEx;
  return useQuery<RecordType, unknown, RecordType>(
    [url],
    () => dataProvider.apiFetch<RecordType>(url).then(({ data }) => data),
    options,
  );
};
