import React from 'react';
import { Box, styled, TextField } from '@mui/material';
import {
  FieldProps,
  useNotify,
  useRecordContext,
  useResourceContext,
  useUpdate,
} from 'react-admin';

const TextFieldStyled = styled(TextField)`
  max-width: 120px;

  input {
    // text-align: center;
    padding: 4px 8px;
  }
`;

interface IProps extends FieldProps {
  onSuccessUpdate: () => void;
}

export const QuantField = ({ onSuccessUpdate }: IProps) => {
  const resourceName = useResourceContext();
  const notify = useNotify();
  const rec = useRecordContext();

  const [doUpdate] = useUpdate(undefined, undefined, {
    onError: (error) => {
      notify(String(error), { type: 'error', autoHideDuration: 3000 });
    },
    onSuccess: onSuccessUpdate,
  });

  if (!rec) {
    return null;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const n = Number(event.target.value);
    if (!isNaN(n) && n > 0) {
      doUpdate(resourceName, {
        id: rec.id,
        data: { quant: n },
        previousData: rec,
      });
    }
  };

  return (
    <Box>
      <TextFieldStyled
        type="number"
        size="small"
        variant="outlined"
        value={rec.quant}
        onChange={handleChange}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </Box>
  );
};
