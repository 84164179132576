import { OrderList } from './OrderList';
import { OrderShow } from './OrderShow';

const URL = 'orders';

export default {
  name: URL,
  // options: { label: 'Товары' },
  list: OrderList,
  show: OrderShow,
};
