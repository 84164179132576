import React, { ErrorInfo } from 'react';
import { Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Report';
import { Title, TitleComponent, useTranslate } from 'react-admin';

interface IProps {
  title?: TitleComponent;
  errorInfo?: ErrorInfo;
}

export const ErrorComponent: React.FC<IProps> = ({ title }) => {
  const translate = useTranslate();
  return (
    <>
      {title && <Title title={title} />}
      <Typography variant="h1" role="alert">
        <ErrorIcon />
        {translate('ra.page.error')}
      </Typography>
      <div>{translate('ra.message.error')}</div>
    </>
  );
};
