import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography } from '@mui/material';
import LogoIcon from '@mui/icons-material/Album';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface IProps {
  sx?: SxProps<Theme>;
  variant?: 'h5' | 'h6';
}

const Logo: React.FC<IProps> = ({ sx, variant }) => {
  return (
    <Link
      sx={{
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: 'inherit',
        // marginRight: 16,
        mr: 2,
        ...sx,
      }}
      component={RouterLink}
      to="/"
    >
      <LogoIcon sx={{ mr: 1 }} />
      <Typography
        variant={variant ?? 'h6'}
        component="div"
        noWrap
        sx={{
          // mr: 2,
          fontFamily: 'monospace',
          fontWeight: 700,
          letterSpacing: '.3rem',
          // color: 'inherit',
          // textDecoration: 'none',
        }}
      >
        MZ-UI
      </Typography>
    </Link>
  );
};

export default Logo;
