import { Options, fetchUtils, DataProvider } from 'react-admin';
import { stringify } from 'query-string';
import { config } from './config';

const API_URL = `${config.apiHost}${config.apiPath}`;

export const httpClient = (url: string, options: Options = {}) => {
  const token = localStorage.getItem('token');
  if (token) {
    options.user = { authenticated: true, token: `Bearer ${token}` };
  }
  return fetchUtils.fetchJson(url, options);
};

export const apiFetch = (resource: string, query?: object, options: Options = {}) => {
  let url = `${API_URL}/${resource}`;
  if (query) {
    url = url.concat('?', stringify(query));
  }
  return httpClient(url, options);
};

export const dataProvider: DataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query: any = {
      sort: `${field}|${order}`.toLowerCase(),
      page,
      limit: perPage,
      ['fetch-total']: 1, // !!! always !!!
      // filter: params.filter ? JSON.stringify(params.filter) : undefined,
    };
    Object.entries(params.filter as Record<string, any>).forEach(([name, value]) => {
      query[name] = value;
    });

    return apiFetch(resource, query).then(({ json }) => ({
      data: json.data,
      total: json.total ?? 10,
    }));
  },

  getOne: (resource, params) => {
    return apiFetch(`${resource}/${params.id}`).then(({ json }) => json);
  },

  getMany: (resource, params) => {
    return apiFetch(resource, { ids: params.ids }).then(({ json }) => json);
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    return apiFetch(resource, query).then(({ json }) => ({
      data: json,
      total: 10,
    }));
  },

  create: (resource, params) => {
    return apiFetch(resource, undefined, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  update: (resource, params) => {
    return apiFetch(`${resource}/${params.id}`, undefined, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return apiFetch(resource, query, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  delete: (resource, params) => {
    return apiFetch(`${resource}/${params.id}`, undefined, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }));
  },

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return apiFetch(resource, query, {
      method: 'DELETE',
      body: JSON.stringify((params as any).data),
    }).then(({ json }) => ({ data: json }));
  },
  // direct
  apiFetch: (url: string) => {
    return apiFetch(url).then(({ json }) => json);
  },
};
