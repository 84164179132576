import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useListContext } from 'react-admin';
import { WishlistTotals } from './WishlistTotals';

export const WishlistListBottom = () => {
  const { total, isLoading, filterValues } = useListContext();

  if (!isLoading && total === 0 && !Object.keys(filterValues).length) {
    return null;
  }

  return (
    <Grid container gap={3} sx={{ marginBottom: 2 }}>
      <WishlistTotals />
    </Grid>
  );
};
