import { WishlistList } from './WishlistList';

export const RESOURCE_WISHLIST = 'wishlist';

export default {
  name: RESOURCE_WISHLIST,
  // options: { label: 'Товары' },
  list: WishlistList,
};

export * from './types';
