import React from 'react';
import { Link } from 'react-router-dom';
import { ListItemIcon, ListItemText, MenuItem, MenuItemProps } from '@mui/material';
import { useUserMenu } from 'react-admin';

interface IProps extends MenuItemProps {
  to: string;
  label: string;
  leftIcon: React.ReactElement;
}

export const UserMenuItem = (props: IProps) => {
  const { to, label, leftIcon, ...menuProps } = props;

  const { onClose } = useUserMenu();
  return (
    <MenuItem to={to} component={Link} onClick={onClose} {...menuProps}>
      <ListItemIcon>{leftIcon}</ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </MenuItem>
  );
};
