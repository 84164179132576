import React from 'react';
import { ListView, ListProps, ExportButton, SortButton, TopToolbar, ListBase } from 'react-admin';
import { WishlistTableView } from './WishlistTableView';
import { WishlistListBottom } from './WishlistListBottom';

const ListActions = () => {
  return (
    <TopToolbar>
      <SortButton fields={['good.name', 'good.code']} />
      {/*
      <SelectColumnsButton />
      */}
      <ExportButton />
    </TopToolbar>
  );
};

export const WishlistList: React.FC<ListProps> = (props) => {
  return (
    <ListBase perPage={10} sort={{ field: 'good.code', order: 'DESC' }}>
      <ListView actions={<ListActions />}>
        <WishlistTableView {...props} />
      </ListView>
      <WishlistListBottom />
    </ListBase>
  );
};
