/**
 * url итого в корзине
 */
export const URL_CART_TOTALS = '/cart/totals';

/**
 * /cart/totals
 */
export interface ICartTotalsDto {
  total_quant: number;
  total_sum_i: number;
}
