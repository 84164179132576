const ruMessages = {
  cart: {
    name: 'Корзина |||| Корзины',
    empty: 'Нет товаров в корзине',
    fields: {
      'good.name': 'Наименование',
      'good.code': 'Артикул',
      'good.barcode': 'Штрих-код',
    },
  },
};

export default ruMessages;
