import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  SearchInput,
  TopToolbar,
  SortButton,
  ListProps,
  CreateButton,
} from 'react-admin';

const listFilters = [
  <SearchInput
    key="search"
    name="search"
    source="search"
    alwaysOn
    variant="outlined"
    //  size="small"
    sx={{ width: '10rem' }}
  />,
];

const ListActions = () => {
  return (
    <TopToolbar>
      <SortButton fields={['id', 'name']} />
      <CreateButton />
    </TopToolbar>
  );
};

export const FaceList: React.FC<ListProps> = (props) => {
  return (
    <List {...props} actions={<ListActions />} bulkActionButtons={false} filters={listFilters}>
      <Datagrid rowClick="show">
        <NumberField source="id" />
        <TextField source="shortname" sortable={false} />
        <TextField source="name" />
      </Datagrid>
    </List>
  );
};
