import React from 'react';
import { useQueryClient } from 'react-query';
import { styled } from '@mui/material';
import {
  Datagrid,
  DeleteWithConfirmButton,
  FunctionField,
  Identifier,
  ListProps,
  NumberField,
  RaRecord,
  TextField,
  useCreatePath,
} from 'react-admin';
import { QuantField } from '../common/QuantField';
import { RESOURCE_CANVASS } from '../canvass';
import { URL_WISHLIST_TOTALS } from './index';
import { getCurrencyOpts, numAsCurrency } from '../../utils';

const DeleteWithConfirmButtonStyled = styled(DeleteWithConfirmButton)`
  min-width: 32px;

  .MuiButton-startIcon {
    margin: 0;
  }
`;

export const WishlistTableView: React.FC<Omit<ListProps, 'children'>> = () => {
  const queryClient = useQueryClient();
  const createPath = useCreatePath();
  const handleRowClick = (id: Identifier, resource: string, record: RaRecord) => {
    return createPath({ resource: RESOURCE_CANVASS, id: record.good.id, type: 'show' });
  };

  return (
    <>
      <Datagrid rowClick={handleRowClick} bulkActionButtons={false}>
        <TextField source="good.code" label="Артикул" />
        <TextField source="good.barcode" sortable={false} label="Штрих-код" />
        <TextField source="good.name" label="Наименование" />
        <QuantField
          label="Кол-во"
          sortable={false}
          onSuccessUpdate={() => queryClient.invalidateQueries(URL_WISHLIST_TOTALS)}
        />
        <NumberField source="price_i" sortable={false} label="Цена" options={getCurrencyOpts()} />
        <FunctionField
          source="sum_i"
          sortable={false}
          label="Сумма"
          textAlign="right"
          render={(record: RaRecord) => record && numAsCurrency(record.quant * record.price_i)}
        />
        <DeleteWithConfirmButtonStyled
          label=""
          confirmTitle=""
          confirmContent="Удалить товар из предзаказа?"
        />
      </Datagrid>
    </>
  );
};
