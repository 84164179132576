import React from 'react';
import { useRecordContext } from 'react-admin';
import { OrderDetails } from './OrderDetails';

export const OrderDetailsExpand = () => {
  const orderRec = useRecordContext();
  if (!orderRec) {
    return null;
  }
  return <OrderDetails order_id={orderRec.id} />;
};
