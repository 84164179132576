import React from 'react';
import { useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardActions, CardContent, CardHeader } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useApiFetch } from '../../hooks';
import { IWishlistTotalsDto } from './types';
import { URL_WISHLIST_TOTALS } from './index';
import { numAsCurrency } from '../../utils';

interface IProps {
  isWidget?: boolean;
}

export const WishlistTotals: React.FC<IProps> = ({ isWidget = false }) => {
  const navigate = useNavigate();
  const notify = useNotify();
  const { data } = useApiFetch<IWishlistTotalsDto>(URL_WISHLIST_TOTALS, {
    onError: (error) => notify(String(error), { type: 'error', autoHideDuration: 3000 }),
  });

  return (
    <Card>
      <CardHeader
        title={isWidget ? 'Корзина' : 'Итого'}
        titleTypographyProps={{ variant: 'h6' }}
        sx={{ paddingBottom: 0 }}
      />
      <CardContent>
        <Grid container gap={2} sx={{ mx: 2 }}>
          <Grid>
            Кол-во:
            <Box component="span" sx={{ ml: 1, fontWeight: 'bold' }}>
              {data?.total_quant ?? '?'}
            </Box>
          </Grid>
          <Grid>
            Сумма:
            <Box component="span" sx={{ ml: 1, fontWeight: 'bold' }}>
              {data?.total_sum_i ? numAsCurrency(data.total_sum_i) : '?'}
            </Box>
          </Grid>
        </Grid>

        {/*
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Кол-во:</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>1000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Сумма:</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>1000</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        */}
      </CardContent>
      {isWidget && (
        <CardActions>
          <Button size="small" onClick={() => navigate('/cart')}>
            Перейти
          </Button>
        </CardActions>
      )}
    </Card>
  );
};
