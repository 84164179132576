import React from 'react';
import { Divider } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Logout, UserMenu } from 'react-admin';
import { UserMenuItem } from './UserMenuItem';

export const CustomUserMenu = () => {
  return (
    <UserMenu>
      <UserMenuItem to="/upload-file" label="Отправить файл заказа" leftIcon={<UploadFileIcon />} />
      <Divider />
      <Logout />
    </UserMenu>
  );
};
