import React from 'react';
import {
  ListView,
  ListProps,
  ExportButton,
  SortButton,
  TopToolbar,
  ListBase,
  SelectColumnsButton,
} from 'react-admin';
import { CartTableView } from './CartTableView';
import { CartListBottom } from './CartListBottom';

const ListActions = () => {
  return (
    <TopToolbar>
      <SortButton fields={['good.name', 'good.code']} />
      <SelectColumnsButton />
      <ExportButton />
    </TopToolbar>
  );
};

export const CartList: React.FC<ListProps> = (props) => {
  return (
    <ListBase perPage={10} sort={{ field: 'good.code', order: 'DESC' }}>
      <ListView actions={<ListActions />}>
        <CartTableView {...props} />
      </ListView>
      <CartListBottom />
    </ListBase>
  );
};
