import React from 'react';
import { ListProps, ReferenceInput, SearchInput, SelectInput, TextInput } from 'react-admin';
import { GoodList } from '../catalog/GoodList';
import { IInputQtyProps } from '../catalog/InputQty';
import { RESOURCE_WISHLIST } from '../wishlist';

export const canvassInputQtyProps: IInputQtyProps = {
  resourceAdd: RESOURCE_WISHLIST,
  fieldQuant: 'wl_quant',
  captionBuy: 'Предзаказ',
  captionBuyed: 'Предзаказано',
};

const listFilters = [
  <ReferenceInput
    key="group_id"
    name="group_id"
    source="group_id"
    reference="canvass-groups"
    // sort={{ field: 'name', order: 'ASC' }}
    alwaysOn
  >
    <SelectInput
      source="name"
      optionValue="id"
      optionText="name"
      emptyText="<Все>"
      label="Канвас"
    />
  </ReferenceInput>,
  <SearchInput
    key="search"
    name="search"
    source="name"
    // label="Поиск"
    alwaysOn
    // variant="outlined"
    //  size="small"
    sx={{ width: '10rem' }}
  />,
  <TextInput
    key="code"
    name="code"
    source="code"
    label="Артикул"
    variant="outlined"
    // size="small"
    resettable
    sx={{ width: '10rem' }}
  />,
  <TextInput
    key="barcode"
    name="barcode"
    label="Штрих-код"
    source="barcode"
    // variant="outlined"
    // size="small"
    resettable
    sx={{ width: '10rem' }}
  />,
];

/**
 *
 */
export const CanvassList = (props: ListProps) => {
  // console.log('== children', props.children);
  return <GoodList filters={listFilters} {...canvassInputQtyProps} {...props} />;
};
