import React, { ErrorInfo, HtmlHTMLAttributes } from 'react';
import { CoreLayoutProps, Error } from 'react-admin';
import { ErrorBoundary } from 'react-error-boundary';
import { CssBaseline, Container } from '@mui/material';
import Header from './Header';
import { ErrorComponent } from './ErrorComponent';

export interface LayoutProps
  extends CoreLayoutProps,
    Omit<HtmlHTMLAttributes<HTMLDivElement>, 'title'> {}

const Layout = (props: LayoutProps) => {
  const { children, title } = props;

  const [errorInfo, setErrorInfo] = React.useState<ErrorInfo>();

  const handleError = (error: Error, info: ErrorInfo) => {
    setErrorInfo(info);
  };

  return (
    <>
      <CssBaseline />
      <Header />
      <Container maxWidth="xl" sx={{ minHeight: '100%' }}>
        <main id="main-content">
          <ErrorBoundary
            onError={handleError}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <Error
                error={error}
                errorComponent={ErrorComponent}
                errorInfo={errorInfo}
                resetErrorBoundary={resetErrorBoundary}
                title={title}
              />
            )}
          >
            {children}
          </ErrorBoundary>
        </main>
      </Container>
    </>
  );
};

export default Layout;
