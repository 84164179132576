import React from 'react';
import { DatagridConfigurable, DateField, ListProps, NumberField, TextField } from 'react-admin';
import { OrderDetailsExpand } from './OrderDetailsExpand';
import { getCurrencyOpts } from '../../utils';
import { ContextMenu } from './ContextMenu';

export const OrderTableView: React.FC<Omit<ListProps, 'children'>> = () => {
  return (
    <DatagridConfigurable
      omit={['id']}
      rowClick="expand"
      expand={<OrderDetailsExpand />}
      bulkActionButtons={false}
      rowStyle={(record) => {
        return record.actived == 0 ? { backgroundColor: '#eee' } : undefined;
      }}
    >
      <NumberField source="id" />
      <DateField source="dttm" label="Дата заказа" showTime={false} />
      <TextField source="number" label="Номер" />
      <NumberField source="totals.quant" label="Кол-во" sortable={false} />
      <NumberField
        source="totals.sum_i"
        label="Сумма"
        sortable={false}
        options={getCurrencyOpts()}
      />
      <TextField source="remark" label="Примечание" />
      <TextField source="status" label="Статус" />
      <ContextMenu label="≡" />
      {/*
      <DeleteWithConfirmButton label="Отменить" confirmTitle="" confirmContent="Отменить заказ?" />
      */}
    </DatagridConfigurable>
  );
};
