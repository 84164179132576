import React from 'react';
import { Edit } from 'react-admin';
import { FaceForm } from './FaceForm';

export const FaceEdit = () => {
  return (
    <Edit>
      <FaceForm />
    </Edit>
  );
};
