import React from 'react';
import { ExportButton, ListProps, SelectColumnsButton, SortButton, TopToolbar } from 'react-admin';
import { List } from '../../components/List';
import { OrderTableView } from './OrderTableView';

const ListActions = () => {
  return (
    <TopToolbar>
      <SortButton fields={['dttm']} />
      <SelectColumnsButton />
      <ExportButton />
    </TopToolbar>
  );
};

/**
 *
 */
export const OrderList = (props: ListProps) => {
  return (
    <List
      actions={<ListActions />}
      bulkActionButtons={false}
      perPage={10}
      sort={{ field: 'dttm', order: 'DESC' }}
      {...props}
    >
      <OrderTableView {...props} />
    </List>
  );
};
