import React from 'react';
import { AppBar, Box, Theme, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { LoadingIndicator, ToggleThemeButton } from 'react-admin';
import Logo from './Logo';
import MainMenu from './MainMenu';
import { APP_VERSION } from '../../config';
import { CustomUserMenu } from './CustomUserMenu';

const Header = () => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));
  return (
    <AppBar position="static">
      <Toolbar variant="dense" sx={{ justifyContent: isDesktop ? 'flex-start' : 'space-between' }}>
        {isDesktop ? (
          <>
            <Logo variant="h6" />
            <MainMenu isDesktop={isDesktop} />
          </>
        ) : (
          <>
            <MainMenu isDesktop={isDesktop} />
            <Logo variant="h5" />
          </>
        )}
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="caption" component="div">
            v.{APP_VERSION}
          </Typography>
          <ToggleThemeButton />
          <LoadingIndicator sx={{ '& .RaLoadingIndicator-loader': { marginTop: 1 } }} />
          <CustomUserMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
