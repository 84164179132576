/**
 * url итого в предзаказе
 */
export const URL_WISHLIST_TOTALS = '/wishlist/totals';

/**
 * /cart/totals
 */
export interface IWishlistTotalsDto {
  total_quant: number;
  total_sum_i: number;
}
