import React from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { useTranslate } from 'react-admin';

const APP_TITLE = 'MZ';

interface IProps {
  subTitle: string;
  args: any;
}

export const Title: React.FC<IProps> = ({ subTitle, args }) => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  const translate = useTranslate();
  const text = translate(subTitle, { ...args, _: subTitle });

  return isDesktop ? (
    <span>
      {APP_TITLE}
      {text ? ` - ${text}` : ''}
    </span>
  ) : (
    <span>{text ? text : APP_TITLE}</span>
  );
};
